import styled from 'styled-components';

export default styled.h1`
  position: relative;
  width: fit-content;
  a {
    text-decoration: unset;
    color: currentColor;
  }
  &:before {
    z-index: -1;
    content: '';
    bottom: 0px;
    left: -5px;
    position: absolute;
    width: calc(100% + 10px);
    height: 1rem;
    background: #BFD556;
  }
`;