const size = {
  mobile: '375px',
  tablet: '768px',
  desktop: '1024px',
};

export default Object.keys(size).reduce((acc, key) => {
  acc[key] = style => `
    @media (min-width: ${size[key]}) {
      ${style};
    }
  `
  return acc
}, {});
