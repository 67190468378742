import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components';

const rotate = keyframes`
  from { transform: rotate(0deg) }
  to { transform: rotate(359deg) }
`;

const StyledIcon = styled.svg`
  ${props => props.fill && `fill: ${props.fill};`}
  ${props => props.stroke && `stroke: ${props.stroke};`}
  ${props => props.spin && css`animation: ${rotate} 1s linear infinite;`}
`;

const Icon = ({ icon: { id }, ...props }) => (
  <StyledIcon {...props} >
    {' '}
    <use xlinkHref={`#${id}`} />
    {' '}
  </StyledIcon>
);

Icon.defaultProps = {
  fill: null,
  stroke: null,
  spin: false,
};

Icon.propTypes = {
  spin: PropTypes.bool,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  icon: PropTypes.object.isRequired,
};

export default Icon;
